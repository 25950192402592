<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
  
      <div v-if="gender.length > 0">
        <ProfileEditFormPanel
          :form="form"
          :gender="gender"
          :consentShow="consentShow"
          :customField="customField"
          :questionList="questionList"
          :v="$v"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
import ProfileEditFormPanel from "../../../components/profile/edit/ProfileEditFormPanel";
import OtherLoading from "@/components/other/OtherLoading";
import {
  required,
  minLength,
  email,
  helpers,
  requiredIf
} from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
import moment from "moment";
export default {
  components: {
    Breadcrumb,
    ProfileEditFormPanel,
    OtherLoading
  },
  validations() {
    return {
      form: {
        first_name_th: { required, alpha },
        last_name_th: { required, alpha },
        email: { email },
        birthday_day: { required },
        birthday_month: { required },
        gender: { required },
        birthday_year: { minLength: minLength(4) },
        home_address: {},
        district: {},
        subdistrict: {},
        province: {},
        zipcode: { minLength: minLength(5) }
      },
      questionList: {
        $each: {
          answer_list: {
            $each: {
              answer: {
                required: requiredIf(function (item) {
                  return !item.answer && item.required === 1;
                })
              }
            }
          }
        }
      }
    };
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ข้อมูลส่วนตัว",
          to: "/profile"
        }
      ],
      form: {
        picture: null,
        profile: null,
        email: "",
        first_name_th: "",
        last_name_th: "",
        first_name_en: "",
        last_name_en: "",
        nickname: "",
        telephone: "",
        birthday: "",
        gender: "",
        total_point: null,
        home_address: "",
        town: "",
        alley: "",
        road: "",
        subdistrict: "",
        district: "",
        province: "",
        zipcode: "",
        birthday_day: "",
        birthday_month: "",
        birthday_year: "",
        is_consent: 0
      },
      gender: [],
      isLoading: true,
      consentShow: false,
      customField: [],
      questionList: []
    };
  },
  async created() {
    await this.getDataProfile();
    await this.getGender();
  },
  methods: {
    async getDataProfile() {
      await this.$store.dispatch("getUserApi");
      this.form = this.$store.state.shortProfile;
      this.form.birthday_year =
        this.form.have_year_birth === false ? "" : this.form.birthday_year;
      // this.form.birthday = moment(this.form.birthday).format('YYYY-MM-DD');
      this.consentShow = this.form.is_consent === 1 ? true : false;
      await this.getCustomField();
    },
    async getCustomField() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/User/custom_field/${this.form.user_guid}`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result === 1) {
            this.customField = data.detail;
            for (const field of this.customField) {
              if (field.answer_list.length > 0) {
                if (field.field_type_id === 2) {
                  let body = {
                    question_id: field.question_id,
                    field_type_id: field.field_type_id,
                    answer_list: field.answer_list
                  };
                  body.answer_list[0].required = field.required;
                  this.questionList.push(body);
                } else {
                  for (const answer of field.answer_list) {
                    this.questionList.push({
                      question_id: field.question_id,
                      field_type_id: field.field_type_id,
                      answer_list: [
                        {
                          answer_id: answer.answer_id,
                          answer: answer.answer,
                          required: field.required
                        }
                      ]
                    });
                  }
                }
              } else {
                if (field.choice_list.length > 0) {
                  for (const choice of field.choice_list) {
                    if (choice.is_default) {
                      this.questionList.push({
                        question_id: field.question_id,
                        field_type_id: field.field_type_id,
                        answer_list: [
                          {
                            answer_id: choice.choice_id,
                            answer: choice.choice,
                            required: field.required
                          }
                        ]
                      });
                    }
                  }
                } else {
                  this.questionList.push({
                    question_id: field.question_id,
                    field_type_id: field.field_type_id,
                    answer_list: [
                      {
                        answer_id: 0,
                        answer: "",
                        required: field.required
                      }
                    ]
                  });
                }
              }
            }
            console.log(this.questionList);
          }
        });
    },
    async getGender() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/gender`)
        .then(data => {
          if (data.result == 1) {
            let genderList = [];
            let genderAll = data.detail;
            for (let i in genderAll) {
              if (genderAll.length > 0) {
                genderList.push({
                  value: genderAll[i].id,
                  text: genderAll[i].name
                });
                this.gender = genderList;
              }
            }
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  margin-top: 80px;
}
</style>