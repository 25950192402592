<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="content-edit-profile mx-3" v-else>
      <div>
        <InputText
          v-model="form.first_name_th"
          textFloat="ชื่อ"
          placeholder="ชื่อ"
          type="text"
          name="first_name_th"
          isRequired
          :isValidate="v.form.first_name_th.$error"
          :v="v.form.first_name_th"
          class="f-regular"
        />
      </div>
      <div>
        <InputText
          v-model="form.last_name_th"
          textFloat="นามสกุล"
          placeholder="นามสกุล"
          type="text"
          name="last_name_th"
          isRequired
          :isValidate="v.form.last_name_th.$error"
          :v="v.form.last_name_th"
          class="f-regular"
        />
      </div>
      <div>
        <InputText
          v-model="form.email"
          textFloat="อีเมล"
          placeholder="อีเมล"
          type="text"
          name="email"
          class="f-regular"
          :isValidate="v.form.email.$error"
          :v="v.form.email"
        />
      </div>
      <!-- <div>
            <label class="label-text">วัน/เดือน/ปีเกิด <span style="color:red">*</span></label>
            <datetime
                type="date"
                placeholder="วัน/เดือน/ปีเกิด"
                class="date-filter"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                :max-datetime="dateFormat()"
                v-model="form.birthday"
                :value="form.birthday"
            ></datetime>
        </div> -->
      <div>
        <InputSelect
          title="วันเกิด"
          text="วันเกิด"
          name="day"
          placeholder="วันเกิด"
          valueField="value"
          textField="text"
          v-bind:options="day"
          v-model="form.birthday_day"
          :value="form.birthday_day"
          @onDataChange="val => (form.birthday_day = val)"
          isRequired
          :isValidate="v.form.birthday_day.$error"
          :v="v.form.birthday_day"
          class="f-regular"
          ref="day"
        />
      </div>
      <div>
        <InputSelect
          title="เดือนเกิด"
          text="เดือนเกิด"
          name="birthday_month"
          placeholder="เดือนเกิด"
          valueField="value"
          textField="text"
          v-bind:options="month"
          v-model="form.birthday_month"
          :value="form.birthday_month"
          @onDataChange="val => (form.birthday_month = val)"
          isRequired
          :isValidate="v.form.birthday_month.$error"
          :v="v.form.birthday_month"
          class="f-regular"
          ref="birthday_month"
        />
      </div>
      <div>
        <InputText
          v-model="form.birthday_year"
          textFloat="ปีเกิด"
          textRemark="(กรุณากรอกปี ค.ศ.)"
          placeholder="ปีเกิด"
          type="number"
          name="birthday_year"
          class="f-regular"
          :isValidate="v.form.birthday_year.$error"
          :v="v.form.birthday_year"
          oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
        />
      </div>
      <div>
        <InputSelect
          title="เพศ"
          text="เพศ"
          name="gender"
          placeholder="เพศ"
          valueField="value"
          textField="text"
          v-bind:options="gender"
          v-model="form.gender"
          :value="form.gender"
          isRequired
          v-on:onDataChange="handleChangeGender"
          :isValidate="v.form.gender.$error"
          :v="v.form.gender"
          class="f-regular"
          ref="gender"
        />
      </div>
      <div
        class="mt-2"
        @click="
          $router.push({ path: 'telephone', query: { tel: form.telephone } })
        "
      >
        <label>เบอร์โทรศัพท์ <span class="remark">*</span></label>
        <b-row class="box-tel">
          <b-col class="custom-pd-tel">{{ form.telephone }}</b-col>
          <b-col class="text-right">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </b-col>
        </b-row>
      </div>
      <div class="mt-2">
        <InputText
          v-model="form.home_address"
          textFloat="ที่อยู่"
          placeholder="ที่อยู่"
          type="text"
          name="home_address"
          :v="v.form.home_address"
          class="f-regular"
        />
      </div>
      <div>
        <InputText
          v-model="form.district"
          textFloat="ตำบล/แขวง"
          placeholder="ตำบล/แขวง"
          type="text"
          name="district"
          :v="v.form.district"
          class="f-regular"
        />
      </div>
      <div>
        <InputText
          v-model="form.subdistrict"
          textFloat="อำเภอ/เขต"
          placeholder="อำเภอ/เขต"
          type="text"
          name="subdistrict"
          :isValidate="v.form.subdistrict.$error"
          :v="v.form.subdistrict"
          class="f-regular"
        />
      </div>
      <div>
        <InputText
          v-model="form.province"
          textFloat="จังหวัด"
          placeholder="จังหวัด"
          type="text"
          name="province"
          :isValidate="v.form.province.$error"
          :v="v.form.province"
          class="f-regular"
        />
      </div>
      <div>
        <InputText
          v-model="form.zipcode"
          textFloat="รหัสไปรษณีย์"
          placeholder="รหัสไปรษณีย์"
          type="number"
          inputmode="numeric"
          name="zipcode"
          oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
          :isValidate="v.form.zipcode.$error"
          :v="v.form.zipcode"
          class="f-regular"
        />
      </div>
      <div v-if="customField.length > 0">
        <CustomFieldPanel
          :customField="customField"
          :v="v"
          :questionList="questionList"
        />
      </div>
      <div v-if="!consentShow">
        <b-row class="mt-4">
          <b-col>
            <b-form-checkbox
              v-model="form.is_consent"
              class="consent-text"
              name="checkbox-1"
              ref="input"
              :value="1"
              :unchecked-value="0"
            >
              ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น รวมถึงข่าวสารจาก Hourglass
              Thailand ตามที่ระบุไว้ใน
              <span class="text-underline" @click.prevent="modalPolicyOpen"
                >นโยบายคุ้มครองข้อมูลส่วนบุคคล</span
              >
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="text-center my-2">
          <b-col class="consent-text">
            <div>
              <span class="text-underline pr-2" @click.prevent="modalTermsOpen"
                >ข้อกำหนดเงื่อนไข</span
              >
              <span class="text-underline" @click.prevent="modalPolicyOpen"
                >นโยบายคุ้มครองข้อมูลส่วนบุคคล</span
              >
            </div>
            <div v-if="$route.query.pdpa !== '4'">
              <span class="text-underline" @click="$router.push('/setting')"
                >จัดการข้อมูลส่วนตัว</span
              >
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="register-bottom fixed-bottom bg-white p-2 shadow">
        <b-button
          class="w-100 btn-register"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          @click="validateEmail()"
          :disabled="disabledBtn"
          >บันทึก</b-button
        >
      </div>
      <!-- <div class="text-center mt-3 footer-bottom fixed-bottom" @click="validateEmail()">
            <button class="btn-edit">บันทึก</button>
        </div> -->
      <ModalPolicy ref="modalPolicy" />
      <ModalTermAndConditios ref="modalTerms" />
      <ModalError ref="modalError" :text="successMessage" />
    </div>
  </div>
</template>

<script>
import InputText from "../../../components/input/InputText";
import InputSelect from "../../../components/input/InputSelect";
import moment from "moment";
import ModalError from "@/components/alert-modal/ModalError";
import CustomFieldPanel from "@/components/custom-fields/CustomFieldPanel";
import OtherLoading from "@/components/other/OtherLoading";
import ModalPolicy from "@/components/alert-modal/ModalPolicy";
import ModalTermAndConditios from "@/components/alert-modal/ModalTermAndConditios";
export default {
  components: {
    InputText,
    ModalError,
    InputSelect,
    CustomFieldPanel,
    OtherLoading,
    ModalPolicy,
    ModalTermAndConditios
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    gender: {
      required: true,
      type: Array
    },
    consentShow: {
      required: true,
      type: Boolean
    },
    customField: {
      required: true,
      type: Array
    },
    questionList: {
      required: true,
      type: Array
    },
    v: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      successMessage: "",
      emailFirst: "",
      disabledBtn: false,
      day: [
        { value: "", text: "วันเกิด", disabled: true },
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
        { value: "07", text: "07" },
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" }
      ],
      month: [
        { value: "", text: "เดือน", disabled: true },
        { value: "01", text: "มกราคม" },
        { value: "02", text: "กุมภาพันธ์" },
        { value: "03", text: "มีนาคม" },
        { value: "04", text: "เมษายน" },
        { value: "05", text: "พฤษภาคม" },
        { value: "06", text: "มิถุนายน" },
        { value: "07", text: "กรกฎาคม" },
        { value: "08", text: "สิงหาคม" },
        { value: "09", text: "กันยายน" },
        { value: "10", text: "ตุลาคม" },
        { value: "11", text: "พฤศจิกายน" },
        { value: "12", text: "ธันวาคม" }
      ],
      isLoading: false
    };
  },
  created() {
    this.emailFirst = this.form.email;
  },
  methods: {
    handleChangeGender(val) {
      this.form.gender = val;
    },
    dateFormat() {
      let today = new Date();
      return moment(today).format();
    },
    async validateEmail() {
      if (this.form.email !== this.emailFirst) {
        this.v.form.$touch();
        if (this.v.form.$error) {
          return;
        }
        this.v.questionList.$touch();
        if (this.v.questionList.$error) {
          return;
        }
        let chkEmail = {
          Email: this.form.email
        };
        this.isLoading = true;
        await this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/user/ValidateEmail`,
            chkEmail
          )
          .then(data => {
            if (data.result == 1) {
              this.validationFormEdit();
            } else {
              this.successMessage = "Email นี้เคยสมัครแล้ว";
              this.$refs.modalError.show();
              this.isLoading = false;
            }
          });
      } else {
        this.validationFormEdit();
      }
    },
    async validationFormEdit() {
      this.v.questionList.$touch();
      if (this.v.questionList.$error) {
        return;
      }
      this.v.form.$touch();
      if (this.v.form.$error) {
        return;
      }
      this.isLoading = true;
      this.disabledBtn = true;
      delete this.form.birthday;
      if (this.form.have_year_birth === false) {
        if (!this.form.birthday_year) {
          this.form.birthday_year = null;
        }
      }
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/EditUserProfile`,
          this.form
        )
        .then(data => {
          if (data.result == 1) {
            if (this.customField.length > 0) {
              this.updateCustomField();
            } else {
              this.$router.push("/profile");
            }
          } else {
            this.disabledBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        });
    },
    async updateCustomField() {
      let body = {
        user_guid: this.form.user_guid,
        admin_user_guid: this.form.user_guid,
        question_list: this.questionList
      };
      await this.$axios
        .put(
          `${process.env.VUE_APP_API}/api/v1/User/update_custom_answer`,
          body
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.$router.push("/profile");
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    },
    modalPolicyOpen() {
      this.$router.push("/policy");
    },
    modalTermsOpen() {
      this.$router.push("/terms");
    }
  }
};
</script>

<style lang="scss" scoped>
.content-edit-profile {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 65px;
}
::v-deep .date-filter .vdatetime-input {
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 0;
  padding: 15px 10px;
}
.btn-edit {
  background-color: #000;
  color: #fff;
  width: 100%;
  border: none;
  padding: 10px 0;
}
.remark {
  color: red;
}
.box-tel {
  color: #575757;
  margin: 0;
  border-bottom: 1px solid #dbdbdb;
}
.custom-pd-tel {
  padding: 0 10px;
}
.footer-bottom {
  max-width: 800px;
  position: fixed;
  margin: auto;
}
.consent-text {
  font-size: 14px;
  color: #575757;
}
.text-underline {
  text-decoration: underline;
}
</style>